import React, { Component } from 'react';

class Contact extends Component {
	render() {
		return (

			<div className="content">
				<div id="Contact"> </div>
				<h2>Contact</h2>
				<p>
					Email <i>nucleartheoryjobs AT gmail.com  </i> to submit information for this webpage.
				</p>
			</div>

		);
	}
}

export default Contact;
