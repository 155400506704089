import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch
} from "react-router-dom";

import { HashRouter } from 'react-router-dom'

import './App.css';
import './bg.css';
import Menu from './Menu';
import Shuffle from './Shuffle';
import Policies from './Policies';
import Contact from './Contact';
import Archive from './Archive';
import External from './External';
import Item from './Item';
import ScrollToTop from './Scr';

import ReactGA from 'react-ga';
const trackingId = "UA-158293359-1"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);



export default App;


function App() {
	return (
		<Router>
        <ScrollToTop>
		<Switch>
				<Route exact path="/" component={Display} />
				<Route path="/:id" component={Display} />
				<Route exact path="/" component={Display} />
				<Route path="/:id" component={Display} />
		 </Switch>
        </ScrollToTop>
    	</Router>
	);

}

function flist(year_str){

   var itemlist = [
    [
        "https://indiana.peopleadmin.com/postings/25595"
        , "IU"
        , "Indiana University, Bloomington "
        , "Assistant Professor, funded jointly with ExoHad Topical Collaboration"
        , "November 8, 2024"
        , <>  </>
        , <>       </>
    ],
    [
        "https://apply.interfolio.com/152240"
        , "UTK"
        , "University of Tennessee, Knoxville"
        , "Assistant Professor, funded jointly with NTNP DOE Topical Collaboration"
        , "November 22, 2024"
        , <>  </>
        , <>       </>
    ],
    ];

   if(year_str==="2024")
   {
	  itemlist = [
    [
        "https://facultycareers.fiu.edu/?posting=530979"
        , "FIU"
        , "Florida International University"
        , "Assistant Professor"
        , "Nov 30, 2023"
        , <> Shohini Bhattacharya <br />
	     ... </>
        , <>       </>
    ],
    [
        "https://inspirehep.net/jobs/2675946"
        , "Iowa"
        , "University of Iowa (HEP)"
        , "Assistant Professor"
        , "Nov 28, 2023"
        , <> Matheus Hostert <br />
	     Niklas Mueller <br />
	     Zahra Tabrizi  <br />
	     Yu-Dai Tsai <br />
	     ... </>
        , <>       </>
    ],
    [
        "https://jobslist.kent.edu/cw/en-us/job/499905/faculty-tenure-track9-mo"
        , "Kent State"
        , "Kent State University and HEFTY DOE Topical Collaboration Bridge"
        , "Assistant Professor"
        , "Jan 1, 2024"
        , <> João Barata <br />
	    Kirill Boguslavski <br />
	    Andrew Hanlon <br />
	    ... </>
        , <> Andrew Hanlon (accepted) </>
    ],
    [
        "https://academicjobsonline.org/ajo/jobs/25843"
        , "MIT"
        , "Massachusetts Institute of Technology (Center for Theoretical Physics)"
        , "Assistant Professor"
        , "Dec 1, 2023"
        , <> ... </>
        , <>       </>
    ],
    [
        "https://careers.pageuppeople.com/782/cw/en-us/job/516103/assistant-professorfribnscl-faculty"
        , "MSU"
        , "Michigan State University"
        , "Open rank faculty position in theoretical nuclear science"
        , "Nov 15, 2023"
        , <> Agnieszka Sorensen <br />
	     Jan Steinheimer <br />
	     ... 
	    </>
        , <> Agnieszka Sorensen (accepted) </>
    ],
    [
        "https://inspirehep.net/jobs/2725754"
        , "NMSU"
        , "New Mexico State University"
        , "Tenure track faculty position in computational theoretical nuclear physics - bridged with RIKEN-BNL"
        , "Jan 2, 2024"
        , <> Shohini Bhattacharya <br />
	     Raza Sufian <br />
	     AJFM <br />
	    ...
	    </>
        , <> Shohini Bhattacharya (declined) <br />
	    Raza Sufian (accepted) </>
    ],
    [
        "https://apply.interfolio.com/130592"
        , "SBU"
        , "Stony Brook University"
        , "Assistant Professor in Theoretical Nuclear Physics - funded jointly with QGT DOE Topical Collaboration"
        , "Nov 18, 2023"
        , <> Shohini Bhattacharya <br />
	     Adrien Florio <br />
	     Kyle Lee <br />
	     Niklas Mueller <br />
	     ... </>
        , <>       </>
    ],
   [
        "https://academicjobsonline.org/ajo/jobs/26610"
        , "TU"
        , "Temple University"
        , "Tenure-track faculty position in Theoretical Nuclear Physics - funded jointly with QGT DOE Topical Collaboration and possibly RIKEN-BNL"
        , "Dec 8, 2023"
        , <> Shohini Bhattacharya <br />
	     Farid Salazar <br />
	     Chien-Yeah Seng <br />
	    ... </>
        , <> Farid Salazar (accepted) </>
    ],
    [
        "https://academicjobsonline.org/ajo/jobs/26116"
        , "Uconn"
        , "University of Connecticut (Storrs Campus)"
        , "Tenure-track Assistant Professor in experimental or theoretical nuclear physics"
        , "Dec 15, 2023"
        , <> Shohini Bhattacharya <br />
	    ... </>
        , <> Shohini Bhattacharya (accepted) </>
    ],
    ]; 
   }
   if(year_str==="2023")
   {
    itemlist = [
    [
        "https://inspirehep.net/jobs/2172047"
        , "ANL"
        , "Argonne National Laboratory"
        , "Assistant Physicist in Nuclear Theory"
        , "Dec. 23, 2022"
        , <> ... </>
        , <>     </>
    ],
    [
        "https://inspirehep.net/jobs/2606406"
        , "W&M"
        , "The College of William & Mary"
        , "Assistant Professor of Physics – supported by a DOE Topical Collaboration"
        , "Jan. 16, 2023"
        , <> Andrew Hanlon <br />
	     Andrew Jackura <br />
	     Arkaitz Rodas <br />
	     ...
	  </>
        , <> ... <br />
	     Andrew Jackura (accepted)
	  </>
    ],
    [
        "https://www.int.washington.edu/job-opportunities/research-assistant-professor-int-fellow"
        , "Institute for Nuclear Theory"
        , "Institute for Nuclear Theory"
        , "INT Fellow (Research Assistant Professor, limited up to 5 years)"
        , "Jan. 19, 2023"
        , <> Shohini Bhattacharya <br />
	     Farid Salazar <br />
	     Agnieszka Sorensen <br />
	     ... </>
        , <> Farid Salazar (accepted) </>
    ],
    [
        "https://www.llnl.gov/join-our-team/careers/find-your-job/all/nuclear%20theory/3743990000626199"
        , "LLNL"
        , "Lawrence Livermore National Laboratory"
        , "Staff Scientist in Nuclear Theory"
        , "unspecified"
        , <> ... </>
        , <>     </>
    ],
    [
        "https://inspirehep.net/jobs/2622515"
        , "ODU"
        , "Old Dominion University and Thomas Jefferson National Accelerator Facility "
        , "2 joint Assistant Faculty positions (ODU & JLab), 1 supported by a DOE Topical Collaboration, another with the possibility of Associate"
        , "Jan. 31, 2023"
        , <> ... </>
        , <>     </>
    ],
    [
        "https://recruit.ucdavis.edu/JPF05241"
        , "UC Davis"
        , "University of California Davis"
        , "Assistant Professor, Department of Physics and Astronomy (open search)"
        , "Nov. 18, 2022"
        , <> ... </>
        , <>     </>
    ],
    ]
   }

    if(year_str==="2022")
    {
        itemlist =
            [
            [
                "https://facultycareers.fiu.edu/?posting=525005"
                , "FIU"
                , "Florida International University"
                , "Open-rank tenure-track faculty position"
                , "Nov 1, 2021"
                , <>
                    ...
                </>
                , <> ...
    </>
            ],
            [
                ""
                , "LANL"
                , "Los Alamos National Laboratory, Applied Theoretical Nuclear Physics, T-2"
                , "Staff scientist position"
                , ""
                , <>
                Evan Grohs <br />
                Daniel Odell <br />
                      ...
                </>
                , <> ... </>
            ],
            [
                "https://academicjobsonline.org/ajo/jobs/20472"
                , "LANL"
                , "Los Alamos National Laboratory, Theoretical Division, T-2"
                , "Staff scientist position"
                , "Jan 10, 2022"
                , <>
                Wouter Dekens
                    <br />
    Kaori Fuyuto
                    <br />
    Dorota Grabowska
                    <br />
    Niklas Mueller
                    <br />
    Andrey Sadofyev
                    <br />
    Chien Yeah Seng
                    <br />
    Jim Talbert
                    <br />
                    ...
                </>
                , <> Kaori Fuyuto (accepted)
    </>
            ],
            [
                "https://academicjobsonline.org/ajo/jobs/19869"
                , "MIT"
                , "Massachusetts Institute of Technology"
                , "Tenure-track junior faculty appointment"
                , "Nov 15, 2021"
                , <>
                    ...
                </>
                , <> ...
    </>
            ],
            [
                "https://careers.msu.edu/en-us/job/509183/assistant-professorfribnscl-faculty"
                , "MSU"
                , "Michigan State University"
                , "Junior rank faculty position in theoretical nuclear science at FRIB"
                , "Feb 02, 2022"
                , <>
                Kyle Godbey
                    <br />
                Chloë Hebborn
                    <br />
                Linda Hlophe
                    <br />
                Taylor Whitehead
                    <br />
                Xilin Zhang
                </>
                , <> Chloë Hebborn (accepted)
    </>
            ],
            [
                "https://jobs.physicstoday.org/jobs/15557120/assistant-professor-in-theoretical-nuclear-hadronic-physics-tenure-track"
                , "ODU"
                , "Old Dominion University"
                , "Assistant Professor in Theoretical Nuclear/Hadronic Physics"
                , "Dec 6, 2021"
                , <>
                Dorota Grabowska <br />
                Andrew Jackura <br />
                Joe Karpie <br />
                Felix Ringer
                </>
                , <> Felix Ringer (accepted)
    </>
            ],
                [
                "https://aprecruit.berkeley.edu/JPF03125"
                , "UC Berkeley/LBNL"
                , "University of California, Berkeley and Lawrence Berkeley National Laboratory"
                , "Tenure-track faculty assistant professor in Nuclear Astrophysics/Fundamental Symmetries Theory and staff scientist position at LBNL"
                , "Nov 19, 2021"
                , <>
                Kaori Fuyuto <br />
                Shirly Li <br />
                Felix Ringer <br />
                faculty/permanent staff <br />
                faculty/permanent staff <br />
                ...
                </>
                , <> Raúl Briceño (accepted)
    </>
            ],
            [
                "https://webapps.lsa.umich.edu/Apply/Physics"
                , "UM"
                , "University of Michigan"
                , "Assistant Professor of Physics"
                , "Nov 1, 2021"
                , <>
                Felix Ringer <br />
                ...
                </>
                , <> ...
    </>
            ],
            [
                "https://scse.d.umn.edu/about/faculty-searches"
                , "UMN"
                , "University of Minnesota, Duluth"
                , "Assistant Professor of Physics"
                , "Nov 10, 2021"
                , <>
                    ...
                </>
                , <> ...
    </>
            ],
            [
                "https://apply.interfolio.com/97827"
                , "UND"
                , "University of Notre Dame"
                , "Tenure-track faculty position in theoretical nuclear physics"
                , "Jan 17, 2022"
                , <>
                    ...
                </>
                , <> ...
    </>
            ],
                [
                "http://apply.interfolio.com/93004"
                , "UW"
                , "University of Washington"
                , "Assistant Professor of Physics"
                , "Oct 1, 2021"
                , <>
                    ...
                </>
                , <> ...
    </>
            ]
        ];
    }

	if(year_str==="2021")
	{
	itemlist =[
			[
			"  https://fribtheoryalliance.org/content/jobs.php"
			, "FSU"
			, "Florida State University"
			, "Theoretical Nuclear Physics (partially funded by the FRIB Theory Alliance)"
			, "Feb 1, 2021"
			, <>
				...
			</>
			, <> Caroline Robin (declined)
</>
		],
		[
			"https://apply.interfolio.com/81105"
			, "SBU"
			, "Stony Brook University"
			, "Tenure-track Assistant Professor faculty position in theoretical physics"
			, "Feb 5, 2021"
			, <>
				Felix Ringer
				<br />
				...
			</>
			, <> ...</>
		],
		[
			"https://apply.interfolio.com/78980"
			, "INT"
			, "Institute for Nuclear Theory, UW"
			, "INT Fellow, Research Assistant Professor (limitted term up to 5 years)"
			, "Nov 15, 2020"
			, <>
				Neill Warrington
				<br />
			    ...
			</>
			, <> ...</>
		],
		[
			"https://inspirehep.net/jobs/1824014"
			, "TRIUMF"
			, "TRIUMF, Canada"
			, "Research Scientist in theoretical nuclear physics"
			, "Jan 17, 2021"
			, <>
			...
			</>
			, <> ...</>
		],
		[
			"https://apply.interfolio.com/78521"
			, "UND"
			, "University of Notre Dame"
			, "Tenure-Track Assistant Proffesor in Nuclear Theory"
			, "Nov 15, 2020"
			, <>
			...
			</>
			, <>
Caroline Robin (declined)
			</>
		],
		[
			"https://www.indeed.com/viewjob?jk=1669f0889089619c&q=Physics+Faculty&tk=1eqeb4eljubje802&from=ja&alid=5f3339ed29bbda6bc2e3a499&utm_campaign=job_alerts&utm_medium=email&utm_source=jobseeker_emails&rgtk=1eqeb4eljubje802"
			, "UW"
			, "University of Washington"
			, "Research Assistant Professor – Nuclear Theory Group (limitted term up to 5 years)"
			, "Jan 18, 2021"
			, <>
				Raza Sabbir Sufian
				<br />
			    ...
			</>
			, <> ...</>
		],
		[
			"https://apply.interfolio.com/83721"
			, "WUSTL"
			, "Washington University in St. Louis"
			, "Tenure-Track/Tenured Assistant/Associate Professor in Physics"
			, "Macrh 15, 2021"
			, <>
			...
			</>
			, <>
			...
			</>
		]
	];
	}

	if(year_str==="2020")
	{
	itemlist =[
			[
			"https://labs.inspirehep.net/jobs/1773965"
			, "ANL"
			, "Argonne National Laboratory"
			, "Tenure-Track Assistant Proffesor in Physics"
			, "March 6, 2020"
			, <>
				Felix Ringer
				<br />
				Raza Sufian
				<br />
				...
			</>
			, <> ...</>
		],
		[
			"https://www.jlab.org/sites/default/files/theory/files/nucleartheoryposition_gsu_final.pdf"
			, "GSU"
			, "Georgia State University"
			, "Tenure-Track Assistant Proffesor in Nuclear Theory"
			, "December 1, 2019"
			, <>
			Yang-Ting Chien
			<br />
			Felix Ringer
			<br />
			Matt Sievert
			<br />
			Raza Sufian
			<br />
			...
			</>
			, <>
			Yang-Ting Chien (accepted)
			</>
		],
		[
			"http://apply.interfolio.com/72230"
			, "INT"
			, "Institute for Nuclear Theory, UW"
			, "INT Fellow, Research Assistant Professor (limitted term up to 5 years)"
			, "January 19, 2020"
			, <> Andrey Sadofyev
			<br />
			...
			</>
			, <> ...</>
		],
		[
			"https://academicjobsonline.org/ajo/jobs/14309"
			, "MIT"
			, "Massachusetts Institute of Technology"
			, "Tenure-Track or Tenured Professor in Physics"
			, "November 1, 2019"
			, <> ...
			</>
			, <> ...</>
		],
		[
			"https://jobs.nmsu.edu/postings/38955"
			, "NMSU"
			, "New Mexico State University"
			, "Tenure-Track or Tenured Professor in Physics"
			, "July 19, 2020"
			, <>
			Matt Sievert
			<br />
			...
			</>
			, <>
			Matt Sievert (accepted)
			</>
		],
		[
			"https://academicjobsonline.org/ajo/jobs/14364"
			, "Temple"
			, "Temple University"
			, "Tenure-Track or Tenured Professor in Physics"
			, "December 1, 2019"
			, <>
			Felix Ringer <br />
			...
			</>
			, <> ...</>
		],
		[
			"https://aprecruit.ucr.edu/JPF01224"
			, "UCR"
			, "University of California, Riverside"
			, "Tenure-Track Assistant/Mid-level Professor  in Theoretical Physics"
			, "January 30, 2020"
			, <>
			Matt Sievert
			<br  />
			...
			</>
			, <> ...</>
		],
		[
			"https://phys.washington.edu/assistant-professor-theoretical-physics"
			, "UW"
			, "University of Washington"
			, "Tenure-Track Assistant Professor in Theoretical Physics"
			, "October 28, 2019"
			, <> ...
			</>
			, <> ...</>
		],
		[
			"https://phys.washington.edu/assistant-associate-or-full-professor-theoretical-physics"
			, "UW"
			, "University of Washington"
			, "Open-Rank Professor in Theoretical Physics"
			, "October 28, 2019"
			, <> ...
			</>
			, <> ... </>
		],
		[
			"https://www.wsujobs.com/postings/46905"
			, "WSU"
			, "Washington State University"
			, "Tenure-Track Assistant Professor in Theoretical Physics (Astrophysics)"
			, "October 16, 2019"
			, <> ...
			</>
			, <> ...</>
		],
		[
			"https://www.wsujobs.com/postings/46929"
			, "WSU"
			, "Washington State University"
			, "Tenure-Track Assistant Professor in Theoretical Physics"
			, "November 15, 2019"
			, <> ...
			</>
			, <> ...</>
		]
		];

	}

	if(year_str==="2019")
	{
		itemlist =[
		[
			"https://facultycareers.fiu.edu/?posting=515879"
			, "FIU"
			, "Florida International University"
			, "Tenure-track - Theoretical Nuclear Physics"
			, "November 1, 2018"
			, <>
			Elena Petreska <br />
			Alessandro Pilloni <br />
			... <br />
			</>
			, <> Elena Petreska (declined)  <br />  </>
		],
		[
			"http://www.physastro.iastate.edu/employment"
			, "ISU"
			, "Iowa State University"
			, <> Tenure-track - Theoretical Nuclear Physics <br /> Cluster hire  </>
			, "December 3, 2018"
			, <>
			Henry Lamm <br />
			Andrey Sadofyev <br />
			Srimoyee Sen <br />
			Matt Sievert <br />
			Yong Zhao <br />
			... <br />
			</>
			, <> Srimoyee Sen (accepted) <br />
			</>
		],
		[
			"https://careers-llnl.ttcportals.com/jobs/3606386-nuclear-theory-staff-physicist"
			, "LLNL"
			, "Lawrence Livermore National Laboratory"
			, "Nuclear Theory - Staff Physicist"
			, "Deadline: Mid-April 2019"
			, <>
			Caroline Robin <br />
			Ragnar Stroberg  <br />
			...  <br />
			</>
			, <> </>
		],

		[
			"https://jobs.ncsu.edu/postings/107636"
			, "NCSU"
			, "North Carolina State University"
			, <>
			Tenure-track - Theoretical Nuclear Physics <br />
			FRIB Theory Alliance bridge position
			</>
			, "December 1, 2018"
			, <>
			Sebastian Koenig  <br />
			Caroline Robini <br />
			Srimoyee Sen <br />
			Ragnar Stroberg <br />
			Ingo Tews
			</>
			, <>
			Sebastian Koenig (accepted) <br />
			Ingo Tews
			</>
		],
		[
			"https://academicjobsonline.org/ajo/jobs/12722"
			, "UW"
			, "University of Washington"
			, <>
			"INT Fellow" - Theoretical Nuclear Physics  <br />
			Not tenure eligible
			</>
			, "December 8, 2018"
			, <>
			Joel Lynn   <br />
			Srimoyee Sen <br />
			...
			</>
			, <>
			Joel Lynn   <br />
			Srimoyee Sen <br />
			</>
		],
		[
			"https://inspirehep.net/record/1692033"
			, "WM"
			, "William & Mary"
			, <>
			Tenure-track - Theoretical Nuclear Physics  <br />
			JLAB bridge position
			</>
			, "December 1, 2018"
			, <>
			Chia Cheng (Jason) Chang <br />
			Igor Danilkin <br />
			Jacobo Ruiz de Elvira <br />
			Jeremy Green <br />
			Christopher Monahan <br />
			Alessandro Pilloni
			</>
			, <>
			Christopher Monahan  (accepted)
			</>
		],
		[
			"http://apply.interfolio.com/55554"
			, "Yale"
			, "Yale University"
			, <>
			Tenure-track - Theoretical Physics
			</>
			, "November 17, 2018"
			, <>
			Mauricio Martinez Guerrero <br />
			Ingo Tews  <br />
			...
			</>
			, <>
			</>
		]
	];
	}
	return itemlist;
}


function Display({ match }) {
  	var year_str="2025";
    if(match.params.id==="2024")
	{
		year_str="2024";
	}
    if(match.params.id==="2023")
	{
		year_str="2023";
	}
    if(match.params.id==="2022")
	{
		year_str="2022";
	}
	if(match.params.id==="2021")
	{
		year_str="2021";
	}
	if(match.params.id==="2020")
	{
		year_str="2020";
	}
	if(match.params.id==="2019")
	{
		year_str="2019";
	}
	var itemlist=flist(year_str);

	console.log(itemlist);

	return (
		<div id="layout">

			<div id="main">
				<div id="Home"> </div>
				<div className="header">
				<h1> Theoretical Nuclear Physics Jobs {year_str}</h1>
				<h2> Last update: Indiana University
				<br />
				Date: Sep 7, 2024 </h2>
				</div>

				<Menu />


				<div id="App">
					{itemlist.map ((item, i) =>
					<Item
					key={i}
					reference={item[0]}
					alt={item[1]}
					uni={item[2]}
					description={item[3]}
					deadline={item[4]}
					shortlist={item[5]}
					offerlist={item[6]}
					/>
					)
					}
				</div>

                <div className="wrapper">
					<div className="divider div-transparent div-arrow-down"></div>
				</div>

				<Shuffle />

				<div className="wrapper">
					<div className="divider div-transparent div-arrow-down"></div>
				</div>

				<Policies />

				<div className="wrapper">
					<div className="divider div-transparent div-arrow-down"></div>
				</div>

				<Contact />

				<div className="wrapper">
					<div className="divider div-transparent div-arrow-down"></div>
				</div>

				<Archive />

				<div className="wrapper">
					<div className="divider div-transparent div-arrow-down"></div>
				</div>

				<External />
			</div>
		</div>
  );
}
