import React, { Component } from 'react';

class Shuffle extends Component {
	render() {
		return (
			<div className="content">
		       	<div id="Shuffle"> </div>
				<h2>Faculty Shuffle</h2>
				<div className="pure-g">
					<div className="pure-u-1-4">
					<h3 className="content-subhead-2"></h3>
					Raúl Briceño
					</div>

					<div className="pure-u-1-4">
					<h3 className="content-subhead-2"></h3>
					Old Dominion University & JLab
					</div>

					<div className="pure-u-1-4">
					<h3 className="content-subhead-2"></h3>
					&#8594;
					</div>

					<div className="pure-u-1-4">
					<h3 className="content-subhead-2"></h3>
					UC Berkeley & LBNL
					</div>
				</div>
			</div>
		);
	}
}

export default Shuffle;
